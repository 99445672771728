import { defineStore } from "pinia";
import type { FilterInterface } from "~/models/filters";

import {
  NodeType,
  type CarTreeNode,
  type Node,
  type TreeNode,
  type BrandInterface,
  type ModelInterface,
  type PartTreeNode,
} from "~/models/carTree";
import { useCarTreeStore } from "~/stores/useCarTreeStore";

enum PartCondition {
  ANY = "0",
  NEW = "1",
  USED = "2",
}

interface ErrorBag {
  [key: string]: string[];
}

export const useSellerFilterStore = defineStore("seller-filter-store", () => {
  const client = useSanctumClient();

  const filters = ref<FilterInterface[]>([]);
  const filter = ref<FilterInterface>({
    name: "",
    created_at: "",
    updated_at: "",
  });
  const errors = ref<ErrorBag>({});
  const node = ref<Node>({
    name: "Усі бренди",
    type: NodeType.ROOT,
    selected: false,
  });
  const children = ref<TreeNode[]>([]);
  const selectedBrands = ref<Map<number, TreeNode>>(new Map());
  const selectedModels = ref<Map<number, TreeNode>>(new Map());
  const selectedGenerations = ref<Map<number, TreeNode>>(new Map());
  const selectedParts = ref<Map<number, TreeNode>>(new Map());
  const selectedChildParts = ref<Map<number, TreeNode>>(new Map());
  const partCondition = ref<string>(PartCondition.ANY.toString());
  const partConditionList = ref({
    [PartCondition.ANY.toString()]: "Усі",
    [PartCondition.NEW.toString()]: "Нові",
    [PartCondition.USED.toString()]: "Вживані",
  });
  const snackbar = ref({
    show: false,
    timeout: 5000,
    color: "success",
    text: "",
  });

  const fetchFilters = async (sellerId: number) => {
    const response = await client(`/api/sellers/${sellerId}/filters`);

    filters.value = response.data;

    return response.data;
  };

  const fetchFilter = async (sellerId: number, id: number) => {
    const response = await client(`/api/sellers/${sellerId}/filters/${id}`);

    filter.value = response.data;

    const transformedBrands = response.data.brands.map((brand: CarTreeNode) =>
      transform(brand, NodeType.BRAND)
    );

    const transformedModels = response.data.models.map((model: CarTreeNode) =>
      transform(model, NodeType.MODEL)
    );

    const transformedGenerations = response.data.generations.map(
      (generation: CarTreeNode) => transform(generation, NodeType.GENERATION)
    );

    const transformedParts = response.data.categories.map((part: CarTreeNode) =>
      transform(part, NodeType.PART)
    );

    const transformedChildParts = response.data.child_categories.map(
      (part: CarTreeNode) => transform(part, NodeType.CHILD_PART)
    );

    selectedBrands.value = new Map(
      transformedBrands.map((brand: TreeNode) => [brand.id, brand])
    );

    selectedModels.value = new Map(
      transformedModels.map((model: TreeNode) => [model.id, model])
    );

    selectedGenerations.value = new Map(
      transformedGenerations.map((generation: TreeNode) => [
        generation.id,
        generation,
      ])
    );

    selectedParts.value = new Map(
      transformedParts.map((part: TreeNode) => [part.id, part])
    );

    selectedChildParts.value = new Map(
      transformedChildParts.map((part: TreeNode) => [part.id, part])
    );

    partCondition.value = response.data.part_condition.toString();

    return response.data;
  };

  const deleteFilter = async (sellerId: number, filterId: number) => {
    await client(`/api/sellers/${sellerId}/filters/${filterId}`, {
      method: "DELETE",
    });

    const index = filters.value?.findIndex(
      (filter: FilterInterface) => filter.id === filterId
    );

    if (index !== -1) {
      filters.value?.splice(index as number, 1);
    }
  };

  const saveFilter = async (sellerId: number) => {
    const data = {
      name: filter.value.name,
      part_condition: parseInt(partCondition.value),
      year_from: filter.value.year_from,
      year_to: filter.value.year_to,
      car_brand_ids: selectedBrands.value.size
        ? Array.from(selectedBrands.value.keys())
        : undefined,
      car_model_ids: selectedModels.value.size
        ? Array.from(selectedModels.value.keys())
        : undefined,
      car_generation_ids: selectedGenerations.value.size
        ? Array.from(selectedGenerations.value.keys())
        : undefined,
      part_category_ids: selectedParts.value.size
        ? Array.from(selectedParts.value.keys())
        : undefined,
      part_child_category_ids: selectedChildParts.value.size
        ? Array.from(selectedChildParts.value.keys())
        : undefined,
    };

    const url = filter.value.id
      ? `/api/sellers/${sellerId}/filters/${filter.value.id}`
      : `/api/sellers/${sellerId}/filters`;

    const method = filter.value.id ? "PUT" : "POST";

    try {
      await client(url, {
        method: method,
        body: JSON.stringify(data),
      });

      snackbar.value = {
        show: true,
        timeout: 2000,
        color: "success",
        text: "Фільтр збережено",
      };

      errors.value = {};
    } catch (error) {
      handleError(error);

      snackbar.value = {
        show: true,
        timeout: 2000,
        color: "error",
        text: "Помилка збереження фільтра",
      };
    }
  };

  const handleError = (error: any) => {
    if (error.response && error.response.status) {
      if (error.response.status === 422) {
        errors.value = error.response._data?.errors || {};
      } else {
        throw createError({
          statusCode: error.response.status,
          statusMessage: error.response.statusMessage || "Unknown server error",
        });
      }
    }
  };

  const toggleChildren = (selected: boolean) => {
    children.value.forEach((child) => {
      updateSelections(child, selected);
    });
  };

  const isSelected = (id: number, type: NodeType) => {
    let list: Map<number, TreeNode>;

    switch (type) {
      case NodeType.BRAND:
        list = selectedBrands.value;
        break;
      case NodeType.MODEL:
        list = selectedModels.value;
        break;
      case NodeType.GENERATION:
        list = selectedGenerations.value;
        break;
      case NodeType.PART:
        list = selectedParts.value;
        break;
      case NodeType.CHILD_PART:
        list = selectedChildParts.value;
        break;
      default:
        throw new Error("Unknown node type");
    }

    return list.has(id);
  };

  const updateSelections = (node: Node, selected: boolean) => {
    node.selected = selected;

    switch (node.type) {
      case NodeType.BRAND:
        selected
          ? selectedBrands.value.set(node.id, node)
          : selectedBrands.value.delete(node.id);
        break;
      case NodeType.MODEL:
        selected
          ? selectedModels.value.set(node.id, node)
          : selectedModels.value.delete(node.id);
        break;
      case NodeType.GENERATION:
        selected
          ? selectedGenerations.value.set(node.id, node)
          : selectedGenerations.value.delete(node.id);
        break;
      case NodeType.PART:
        selected
          ? selectedParts.value.set(node.id, node)
          : selectedParts.value.delete(node.id);
        break;
      case NodeType.CHILD_PART:
        selected
          ? selectedChildParts.value.set(node.id, node)
          : selectedChildParts.value.delete(node.id);
        break;
    }
  };

  const resolveLink = (id: number, type: NodeType): string | undefined => {
    const filterId = filter.value.id || "create";

    switch (type) {
      case NodeType.BRAND:
        return `/my-shop/filters/${filterId}/cars/brands/${id}`;
      case NodeType.MODEL:
        return `/my-shop/filters/${filterId}/cars/models/${id}`;
      case NodeType.PART:
        return `/my-shop/filters/${filterId}/parts/${id}`;
      default:
        return undefined;
    }
  };

  const transform = (data: CarTreeNode, type: NodeType): TreeNode => {
    const node: TreeNode = {
      id: data.id,
      name: data.name,
      type: type,
      link: resolveLink(data.id, type),
      selected: isSelected(data.id, type),
    };

    return node;
  };

  const fetchBrands = async () => {
    const response = await client("/api/cars/brands").catch((error) =>
      handleError(error)
    );

    const brands: CarTreeNode[] = response.data;

    children.value = brands.map((brand) => transform(brand, NodeType.BRAND));

    return brands;
  };

  const fetchBrand = async (brandId: number) => {
    const response = await client(`/api/cars/brands/${brandId}`).catch(
      (error) => handleError(error)
    );

    const data: BrandInterface = response.data as BrandInterface;

    node.value = transform(data, NodeType.BRAND);
    children.value = data.models.map((model) =>
      transform(model, NodeType.MODEL)
    );

    return data;
  };

  const fetchModel = async (modelId: number) => {
    const response = await client(`/api/cars/models/${modelId}`).catch(
      (error) => handleError(error)
    );

    const data: ModelInterface = response.data;

    node.value = transform(data, NodeType.MODEL);
    children.value = data.generations.map((generation) =>
      transform(generation, NodeType.GENERATION)
    );

    return data;
  };

  const fetchParts = async () => {
    const response = await client("/api/part-categories").catch((error) =>
      handleError(error)
    );

    const parts: CarTreeNode[] = response.data;

    node.value = {
      id: 0,
      name: "Усі категорії",
      type: NodeType.ROOT,
      selected: false,
    };

    children.value = parts.map((part) => transform(part, NodeType.PART));

    return parts;
  };

  const fetchPart = async (partId: number) => {
    const response = await client(`/api/part-categories/${partId}`).catch(
      (error) => handleError(error)
    );

    const data: PartTreeNode = response.data;

    node.value = transform(data, NodeType.PART);

    children.value =
      data.children?.map((child) => transform(child, NodeType.CHILD_PART)) ||
      [];

    return data;
  };

  return {
    filters,
    filter,
    errors,
    node,
    children,
    partCondition,
    partConditionList,
    selectedBrands,
    selectedModels,
    selectedGenerations,
    selectedParts,
    selectedChildParts,
    snackbar,
    fetchFilters,
    fetchFilter,
    deleteFilter,
    fetchBrands,
    fetchBrand,
    fetchModel,
    fetchParts,
    fetchPart,
    updateSelections,
    toggleChildren,
    saveFilter,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCarTreeStore, import.meta.hot));
}
